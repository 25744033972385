/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.StaffPage,
.StaffCampPage {
  background: #f9f6ef;
  padding: 1rem;
  text-align: center;
}
.StaffPage pre,
.StaffCampPage pre {
  text-align: left;
  width: 100%;
  height: 30rem;
  overflow: scroll;
}
.StaffPage h1,
.StaffCampPage h1 {
  font-size: 3em;
  font-weight: 600;
  color: #745a9e;
  font-family: 'Zona Pro', 'Verdana';
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}
@media (max-width: 991px) {
  .StaffPage h1,
  .StaffCampPage h1 {
    font-size: 2.2em;
    margin-left: 1rem;
  }
}
.StaffPage h2,
.StaffCampPage h2 {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 4rem;
  color: #745a9e;
}
@media (max-width: 991px) {
  .StaffPage h2,
  .StaffCampPage h2 {
    font-size: 1.6rem;
  }
}
.StaffPage .inner .content,
.StaffCampPage .inner .content {
  padding: 1.5rem;
  margin: 3rem;
  background: #fff;
  border-radius: 0.7rem;
  font-weight: 600;
  border-bottom: 0.7rem solid #e6e0d0;
  border-radius: 1rem;
}
@media (max-width: 767px) {
  .StaffPage .inner .content,
  .StaffCampPage .inner .content {
    margin: 0;
  }
}
.StaffPage .inner .content tr,
.StaffCampPage .inner .content tr {
  height: 3rem;
}
.StaffPage .UserSearchForm,
.StaffCampPage .UserSearchForm {
  width: 50%;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .StaffPage .UserSearchForm,
  .StaffCampPage .UserSearchForm {
    width: 100%;
  }
}
