/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.UserInfoTable {
  font-size: 1.4rem;
  text-align: left;
  padding: 1.5rem;
}
.UserInfoTable:hover {
  border: 0.5rem #745a9e;
}
.UserInfoTable > table {
  width: 100%;
}
.UserInfoTable > table td.label {
  width: 20%;
}
.UserInfoTable > table td.value {
  width: 80%;
}
.UserInfoTable > table td.value span.nilValue {
  opacity: 0.5;
}
