/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.UserTree .userCard {
  margin: 1rem;
  padding: 2rem;
  display: inline-block;
  border-radius: 0.7rem;
  background: #f9f6ef;
  font-size: 1.3rem;
}
.UserTree .userCard .userType img {
  height: 2rem;
  margin-right: 1rem;
}
.UserTree .userCard:not(.emptySubuserSlot):not(.active):hover {
  cursor: pointer;
  background: #e6e0d0;
}
.UserTree .userCard.active {
  background: #faffa3;
}
.UserTree .subUsers {
  margin-left: 10%;
}
.UserTree .subUsers .emptySubuserSlot {
  filter: saturate(0);
}
